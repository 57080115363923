<template>
  <div id="printArea">
    <CRow>
      <CCol md="8">
        <h4 class="text-uppercase pb-4">Support Detail</h4>
      </CCol>
      <CCol md="4" class="text-right d-print-none">
        <button @click="refresh()" class="btn btn-primary mr-3" title="Refresh">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="#ffffff"
            width="26"
            height="26"
            version="1.1"
            id="lni_lni-reload"
            x="0px"
            y="0px"
            viewBox="0 0 64 64"
            style="enable-background: new 0 0 64 64"
            xml:space="preserve"
          >
            <g>
              <path
                d="M7.4,28.5c0.3,0,0.6,0,0.8-0.1l11.1-3.9c0.9-0.3,1.4-1.3,1.1-2.2c-0.3-0.9-1.3-1.4-2.2-1.1l-6.7,2.4   c3.3-9.1,12-15.3,22.1-15.3c10.7,0,20.1,7.1,22.7,17.4c0.2,0.9,1.2,1.5,2.1,1.3c0.9-0.2,1.5-1.2,1.3-2.1c-3-11.8-13.8-20-26.1-20   c-12,0-22.4,7.7-25.8,18.9l-3.1-8.7c-0.3-0.9-1.3-1.4-2.2-1.1c-0.9,0.3-1.4,1.3-1.1,2.2l3.8,10.9C5.5,27.9,6.5,28.5,7.4,28.5z"
              />
              <path
                d="M62.6,49.9l-4.1-10.8c-0.2-0.6-0.7-1.1-1.3-1.3c-0.6-0.2-1.2-0.3-1.8,0l-11,4.2c-0.9,0.3-1.4,1.4-1,2.3   c0.3,0.9,1.4,1.4,2.3,1l6.8-2.6c-3.8,7.9-11.9,13.1-21.1,13.1c-10.1,0-19-6.3-22.1-15.7C8.9,39.2,7.9,38.7,7,39   c-0.9,0.3-1.4,1.3-1.1,2.2C9.5,52,19.7,59.3,31.3,59.3c11,0,20.8-6.5,24.8-16.4l3.2,8.3c0.3,0.7,0.9,1.1,1.6,1.1   c0.2,0,0.4,0,0.6-0.1C62.5,51.8,63,50.8,62.6,49.9z"
              />
            </g>
          </svg>
        </button>
        <CButton @click="print()" color="info" shape="pill">Print</CButton>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="4">
        <CCard class="p-5" v-if="support.customer_account">
          <CRow>
            <CCol class="avatar-container">
              <img
                class="mb-2"
                name="cil-user"
                :src="getImgUrl('user.png')"
                alt="User Logo"
              />
            </CCol>

            <CCol class="mt-3">
              <h4>
                {{
                    support.customer_account.first_name
                }}
              </h4>
              <p>
                Account No #<router-link
                  :to="`/consumers/${support.customer_account.id}`"
                  >{{ support.customer_account.account_number }}</router-link
                >
              </p>
            </CCol>
          </CRow>

          <ul class="customer-detail-list">
            <li>
              <p>Category:</p>
              <p>{{ support.customer_account.category }}</p>
            </li>
            <li>
              <p>Class:</p>
              <p>{{ support.customer_account.class }}</p>
            </li>
            <li>
              <p>MI:</p>
              <p>{{ support.customer_account.meter_inspector && support.customer_account.meter_inspector.mi_code }}</p>
            </li>
            <li>
              <p>Mobile No:</p>
              <p>{{ support.customer_account.mobile_number }}</p>
            </li>
            <li>
              <p>Email:</p>
              <p>{{ support.customer_account.email }}</p>
            </li>
            <li>
              <p>Ward No:</p>
              <p>{{ support.customer_account.ward_number }}</p>
            </li>
            <li>
              <p>Address 1:</p>
              <p>{{ support.customer_account.address }}</p>
            </li>
            <li>
              <p>Address 2:</p>
              <p>{{ support.customer_account.address2 }}</p>
            </li>
          </ul>
        </CCard>

        <CRow>
          <CCol sm="12">
            <CCard>
              <h4 class="pb-3">Assigned Teams</h4>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th class="font-weight-bold">Name</th>
                      <th class="font-weight-bold">Type</th>
                    </tr>
                  </thead>
                  <tbody v-if="support">
                    <tr
                      v-for="team in support.teams"
                      :key="team.id"
                    >
                      <td>{{ team.name }}</td>
                      <td>{{ team.type }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="2">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="8">
        <CRow>
          <CCol sm="12">
            <CCard>
              <div class="row mb-3">
                <div class="col-md-7">
                  <h4>Ticket No: {{ support.ticket_no }}</h4>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <!-- <th class="font-weight-bold">Task ID</th> -->
                      <th class="font-weight-bold">Description</th>
                      <th class="font-weight-bold">
                        Meter <br />
                        Serial <br />
                        No
                      </th>
                      <th class="font-weight-bold">Type</th>
                      <th class="font-weight-bold">Status</th>
                      <th class="font-weight-bold">Priority</th>
                      <th class="font-weight-bold">
                        Created <br />
                        At
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="support">
                    <tr class="text-center">
                      <!-- <td>{{ task.uid }}</td> -->
                      <td>{{ support.description }}</td>
                      <td>
                        <router-link
                          :to="`/meters/${support.meter_id}`"
                          v-if="
                            support.meter
                          "
                          >{{ support.meter.hardware_serial_no }}
                        </router-link>
                        <span v-else>N/A</span>
                      </td>
                      <td>{{ support.type }}</td>
                      <td>
                        {{ support.status }}
                      </td>
                      <td>{{ support.priority }}</td>
                      <td>
                        {{
                          support.created_at
                            ? dateFormat(support.created_at)
                            : "N/A"
                        }}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CCard>
              <h4 class="pb-3">Logs</h4>
              <div class="table-responsive">
                <div
                  v-if="support.logs && support.logs.length"
                  class="log-list"
                >
                  <div v-for="log in support.logs" :key="log.id">
                    {{ log.event_description }}
                    , by {{ log.user_name }}
                    <p>{{ log.created_at ? dateFormat(log.created_at) : "N/A" }}</p>
                  </div>
                </div>
                <div v-else>
                  <h5 class="text-center">Data Not Available.</h5>
                </div>
              </div>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SupportView",
  data: () => {
    return {
      supportId: Number,
      updateStatusModal: false,
      deletingState: false,
      loadingState: false,
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  computed: {
    ...mapGetters("CustomerSupport", ["support"]),
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    refresh() {
      this.$store.dispatch("CustomerSupport/getSupportDetail", this.supportId);
    },
    getImgUrl(pic) {
      return require("../../assets/images/" + pic);
    },
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <title>Support Ticket Detail - ${this.support.ticket_no}</title>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener("load", () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
        }, 200);
      });
    },
  },
  watch: {
    $route(to, from) {
      this.supportId = this.$route.params.id;
      this.$store.dispatch("CustomerSupport/getSupportDetail", this.supportId);
    },
  },
  mounted() {
    this.supportId = this.$route.params.id;
    this.$store.dispatch("CustomerSupport/getSupportDetail", this.supportId);
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.update-status-btn-width {
  width: 100%;
}

.hide-footer >>> footer {
  display: none;
}

.custom-close-button >>> button {
  color: aliceblue;
}
.hide-footer >>> footer {
  display: none;
}
.card {
  border: none;
  padding: 30px 20px !important;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}

.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.custom-width {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.log-list {
  border: 1px solid #dadada;
  background-color: #eff0f5;
  border-radius: 4px;
  padding: 15px;
  position: relative;
  font-size: 13px;
  color: #212121;
  word-break: break-word;
  div {
    line-height: 2;
  }
  span {
    color: #9e9e9e;
  }
}
.avatar-container {
  max-width: 100px;
}
.customer-detail-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  li {
    width: 100%;
    float: left;
    p {
      float: left;
    }
    p {
      &:first-of-type {
        width: 35%;
        max-width: 100px;
        font-weight: 600;
      }
      &:last-of-type {
        width: 65%;
      }
    }
  }
}

div {
  .row {
    .card {
      p {
        color: #000;
      }
      .row {
        p {
          color: #5e76e7;
        }
      }
    }
  }
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
